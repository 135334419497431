import { render, staticRenderFns } from "./AssApproveDetail.vue?vue&type=template&id=d45ef19c&scoped=true"
import script from "./AssApproveDetail.vue?vue&type=script&lang=js"
export * from "./AssApproveDetail.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d45ef19c",
  null
  
)

export default component.exports